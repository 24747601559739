import React, { useState, useEffect } from "react"
import { ethers, BigNumber } from "ethers";
import { Container, Navbar, Nav, Image, Button } from "react-bootstrap"
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import Connect from "../buttons/connect";
import HabLogo from "../../images/habLogo.png";
import OpenseaLogo from "../../images/OpenseaLogo.png"
// import Habitats from "../../../abis/rinkeby/Habitats.json";
import Habitats from "../../../abis/mainnet/Habitats.json";

const styles = require("./navBar.module.scss")

type NavBarProps = {
  pageInfo: {
    curPage: string,
  },
  accountAddress: string | null,
  getAndSetAccount: Function,
  connectButtonElement?: HTMLButtonElement,
}

const PageNavbar: React.FC<NavBarProps> = ({ pageInfo = { curPage: "/" }, accountAddress, getAndSetAccount, connectButtonElement }) => {
  const [contract, setContract] = useState<any | null>(null);
  const [shouldCollapseNavBar, setShouldCollapseNavBar] = useState<boolean>(false);
  const [tokenIds, setTokenIds] = useState<Array<number>>([]);

  const handleScroll = () => {
    if (window.scrollY > 2) {
      setShouldCollapseNavBar(true);
    } else {
      setShouldCollapseNavBar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const setupWeb3Info = async () => {
      if (typeof window !== 'undefined' && window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum || 'http://127.0.0.1:8545');

        const { chainId } = await provider.getNetwork();
        const networkData = Habitats.networks[chainId];
        if (networkData) {
          const abi = Habitats.abi;
          const contractAddress = networkData.address;
          const contract = new ethers.Contract(contractAddress, abi, provider.getSigner(accountAddress ? accountAddress : undefined));
          setContract(contract);
        }

        getAndSetAccount();
      }
    }

    setupWeb3Info()
  }, [accountAddress]);

  // Sets contract state
  useEffect(() => {
    if (contract && accountAddress) {
      contract.tokensOfOwner(accountAddress).then((ids: BigNumber[]) => {
        const numberIds = ids.map(id => id.toNumber());
        if (numberIds.length > 0){
          setTokenIds(numberIds);
        }
      });
    }
  }, [contract, accountAddress]);

  const classNames =
    shouldCollapseNavBar
      ? `${styles.navContainer} ${styles.navContainerNoPadding}`
      : styles.navContainer

  const accountNavItem = accountAddress
    ?
    <Button
      variant="outline-primary"
      className={styles.connectButton}
      style={{width: "100%"}}
    >
      {formatAddress(accountAddress)}
    </Button>
    : connectButtonElement
      ?
      <Button
        variant="outline-primary"
        className={styles.connectButton}
        style={{width: "100%"}}
        onClick={() => connectButtonElement.click()}
      >
        CONNECT WALLET
      </Button>
      :
      <Connect
        accountAddress={accountAddress}
        getAndSetAccount={() => getAndSetAccount()}
        connectComponent={
          <Button
            variant="outline-primary"
            className={styles.connectButton}
            style={{width: "100%"}}
          >
            CONNECT WALLET
          </Button>
        }
      />

  return (
    <>
      <Navbar variant="light" expand="lg" fixed="top" className={styles.navbar}>
        <Container className={classNames} fluid>
          <Navbar.Brand href="/" className={styles.navBrand}>
            <Image className={styles.habLogo} src={HabLogo} style={{ borderRadius: "0px" }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <div className={styles.hamburgerBar} />
            <div className={styles.hamburgerBar} />
            <div className={styles.hamburgerBar} />
          </Navbar.Toggle>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className={`${styles.navItemsContainer} align-items-center justify-content-end`}>
              <Nav.Link className={styles.navLink} href={"/research"}>
                research
              </Nav.Link>
              <Nav.Link className={styles.navLink} href={"/work"}>
                work
              </Nav.Link>
              <Nav.Link className={tokenIds && tokenIds.length > 0 ? styles.navLink : styles.disabledLink} href={tokenIds && tokenIds.length > 0 ? "/zone" : "#"}>
                habitat zone
              </Nav.Link>
              <Nav.Item className={`${styles.navLink} ${styles.socialLinks} d-flex`}>
                <a className={styles.socialLink} href="https://twitter.com/habitats_xyz" target="_blank">
                  <FaTwitter size={22} />
                </a>
                <a className={styles.socialLink} href="https://discord.gg/De7BqzU26E" target="_blank">
                  <FaDiscord size={22} />
                </a>
                <a className={styles.socialLink} href="https://opensea.io/collection/habitats-genesis" target="_blank">
                  <Image className={styles.navLogo} src={OpenseaLogo} />
                </a>
              </Nav.Item>
              <Nav.Item className={`${styles.navLink} ${styles.connectNav}`}>
                {accountNavItem}
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

function formatAddress(address: string): string {
  return address.substring(0, 6) + '...' + address.substring(address.length - 4);
}

export default PageNavbar
