/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useState, useEffect}  from "react"
import { PageProps } from "gatsby"
import { Container, Row, Col, Image } from "react-bootstrap"
import { FaDiscord, FaTwitter } from 'react-icons/fa';
import { ethers } from 'ethers';
import Connect from "../pages/zone/connect";
import Navbar from "../navBar"
import Logo from "../../images/logo.png"
import habLogo from "../../images/habLogo.png"
import "bootstrap/dist/css/bootstrap.min.css"
import "./layout.css"

const styles = require("./layout.module.scss")

type LayoutProps = {
  children: React.ReactNode
  pageProps: PageProps,
  accountAddress?: string | null,
  providedGetAndSetAccount?: Function,
  connectButtonElement?: HTMLButtonElement,
}

const Layout: React.FC<LayoutProps> = (props) => {
  const {
    children,
    pageProps,
    accountAddress,
    providedGetAndSetAccount,
    connectButtonElement,
  } = props;
  const { uri = "/" } = pageProps

  const pageInfo = {
    curPage: uri,
  }

  const [account, setAccount] = useState<string | null>(accountAddress ? accountAddress : null);

  const getAndSetAccount = providedGetAndSetAccount ? providedGetAndSetAccount :
    async () => {
      if (typeof window !== 'undefined' && window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum || 'http://127.0.0.1:8545');
        const addresses = await provider.listAccounts();
        const address = addresses[0] as string;
        setAccount(address);
      }
    };

  useEffect(() => {
    getAndSetAccount();
    if (accountAddress) {
      setAccount(accountAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountAddress]);

  return (
    <div className={styles.pageContainer}>
      <Container>
        <Navbar 
          pageInfo={pageInfo} 
          accountAddress={accountAddress ? accountAddress : account}
          getAndSetAccount={providedGetAndSetAccount ? providedGetAndSetAccount : getAndSetAccount}
          connectButtonElement={connectButtonElement}
        />
      </Container>
      <main className={styles.contentWrap}>
        { pageInfo.curPage === '/zone' && !account
            ? <Connect
                headerText="No Access"
                bodyText="In order to view the Habitat Zone you must first conenct your wallet."
              />
            : children
        }
      </main>
      <Container className={styles.footer}>
        <footer>
          <Row className="d-flex">
            <Col className={`${styles.footerSection} d-flex align-items-center justify-content-start col-4`}>
              <p className={styles.footerText}>© 2022 SHoP Architects</p>
            </Col>
            <Col className={`${styles.footerSection} d-flex flex-column align-items-center justify-content-center col-4`}>
            <Row className={styles.logoContainer}>
              <Col className={styles.habContainer}>
                <a href="/" className={styles.habContainer}>
                  <Image className={styles.habLogo} src={habLogo} style={{ borderRadius: "0px" }} />
                </a>
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
               <a className={styles.footerLink} href="https://www.shoparc.com">
                <Image className={styles.shopLogo} src={Logo} style={{ borderRadius: "0px" }} />
              </a>
              </Col>
            </Row>
            </Col>
            <Col className={`${styles.footerSocialLinks} d-flex align-items-center justify-content-end col-4`}>
              <a className={styles.socialLink} href="https://twitter.com/habitats_xyz" target="_blank">
                <FaTwitter size={22} />
              </a>
              <a className={styles.socialLink} href="https://discord.gg/De7BqzU26E" target="_blank">
                <FaDiscord size={22} />
              </a>
            </Col>
          </Row>
        </footer>
      </Container>
    </div>
  )
}

export default Layout
