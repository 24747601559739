import React from 'react';
import { ToastContainer, ToastOptions, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = require('./toast.module.scss');

type ToastProps = {
  text: string | React.ReactElement,
  variant?: 'success' | 'error' | 'warning' | 'info',
  closeTime?: number
  onClose?: () => void,
}

const Toast: React.FC<ToastProps> = (props: ToastProps) => {
  const {
    text,
    variant = 'error',
    closeTime,
    onClose = () => {},
  } = props;

  const options: ToastOptions = {
    toastId: typeof text === 'string' ? text : variant,
    position: 'top-center',
    autoClose: closeTime || 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onClose: () => onClose(),
  };

  switch (variant) {
    case 'error':
      toast.error(text, options);
      break;
    case 'success':
      toast.success(text, options);
      break;
    case 'warning':
      toast.warn(text, options);
      break;
    case 'info':
      toast.info(text, options);
      break;
    default:
      toast.error(text, options);
  };

  return (
    <>
      <ToastContainer
        className={styles.toastContainer}
        position='top-center'
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        rtl={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ fontFamily: 'BrandonGrotesqueWeb' }}
      />
    </>
  );
};

export default Toast;
