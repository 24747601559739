import React, { useEffect, useState }  from "react"
import { providers } from "ethers";
import Web3Modal from "web3modal";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Toast from "../toast";

type ConnectProps = {
  accountAddress: string | null,
  getAndSetAccount: Function,
  connectComponent: React.ReactElement,
  onAccountChanged?: () => void,
}

const Connect: React.FC<ConnectProps> = ({
  accountAddress,
  getAndSetAccount,
  connectComponent,
  onAccountChanged = () => {},
}) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [shouldShowError, setShouldShowError] = useState<boolean>(false);
  const [web3Modal, setWeb3Modal] = useState<Web3Modal | null>(null);

  useEffect(() => {
    // Initiate web3modal
    const providerOptions = {
      coinbasewallet: {
        package: CoinbaseWalletSDK,
        options: {
          appName: 'Coba',
          infuraId: '8e842d3fa2044ae1b8ea8b23647c8eca',
          chainId: 1,
        },
      },
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: '8e842d3fa2044ae1b8ea8b23647c8eca',
        },
      },
    };

    const newWeb3Modal = new Web3Modal({
      cacheProvider: false,
      network: 'mainnet',
      providerOptions,
    });

    setWeb3Modal(newWeb3Modal);
  }, []);

  // Add event listeners
  // TODO (Ben): Fix event listeners
  useEffect(() => {
    if (accountAddress && web3Modal) {
      web3Modal.toggleModal();
      web3Modal.connect().then((provider) => {
        addListeners(provider, getAndSetAccount, onAccountChanged);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountAddress, web3Modal]);

  return (
    <>
      {React.cloneElement(connectComponent, {
        onClick: () => {
          if (web3Modal) {
            connectWallet(
              web3Modal,
              getAndSetAccount,
              setErrorMessage,
              setShouldShowError
            );
          }
        },
      })}
      {shouldShowError && (
        <Toast
          text={errorMessage}
          variant='error'
          onClose={() => {
            setShouldShowError(false);
            setErrorMessage('');
          }}
        />
      )}
    </>
  );
}

async function connectWallet(
  web3Modal: Web3Modal,
  getAndSetAccount: Function,
  setErrorMessage: (str: string) => void,
  setShouldShowError: (bool: boolean) => void,
) {
  try {
    const provider = await web3Modal.connect();
    const ethersProvider = new providers.Web3Provider(provider);
    const userAddress = await ethersProvider.getSigner().getAddress();
    getAndSetAccount(userAddress);
  } catch (e) {
    // Modal is closed by the user - do not display an error to the user
    if (e === 'Modal closed by user') {
      return;
    }

    // User rejected MetaMask connnection
    if (e.message === 'User Rejected') {
      setErrorMessage('Wallet connection rejected.');
      setShouldShowError(true);
    } else {
      setErrorMessage('Error connecting wallet. Please try again.');
      setShouldShowError(true);
    }
  }
}

async function addListeners(
  provider: providers.Web3Provider,
  getAndSetAccount: Function,
  onAccountChanged: () => void,
) {
  provider.on('accountsChanged', (accounts: string[]) => {
    getAndSetAccount(accounts[0]);
    onAccountChanged();
  });

  provider.on('disconnect', () => {
    getAndSetAccount(null);
  });
}

export default Connect