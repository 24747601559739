import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const styles = require("./connect.module.scss");

type ConnectProps = {
  headerText: string,
  bodyText: string,
}

const Connect: React.FC<ConnectProps> = ({headerText, bodyText}) => {

  return (
    <>
      <Container className={styles.contentContainer}>
        <Row className="d-flex justify-content-center">
          <Col className={`col-12 col-md-9 col-lg-8 ${styles.introContainer}`}>
            <p className={styles.headerContainer}>
              <span className={styles.introHeader}>{headerText}</span>
            </p>
            <p className={styles.introBody}>
              {bodyText}
            </p>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Connect
